@tailwind base;
@tailwind components;
@tailwind utilities;

/* react-step-progress-bar */
@import "react-step-progress-bar/styles.css";

body {
  @apply font-sans h-screen text-gray-900 scroll-smooth text-lg lg:text-xl;
}

h1 {
  @apply text-5xl md:text-6xl md:mb-8 leading-snug;
}

h2 {
  @apply text-4xl md:text-5xl md:mb-8;
}

h1, h2, h3, h4, h5, h6 {
    @apply font-sans font-bold;
}

